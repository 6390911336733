import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Maska from 'maska'
import VueExcelXlsx from "vue-excel-xlsx";
import CKEditor from '@ckeditor/ckeditor5-vue';


const app = createApp(App)
.use(Maska)
.use( CKEditor )
.use(ElementPlus)
.use(VueExcelXlsx)
.use(VueCookies, { expire: '6h'})
.use(store)
.use(router)


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')