<template>
  <div class="home">
    <div class="head df align-item-center justify-content-between mb-2">
          <div class="head__left df align-item-end">
              <h1>Statistika</h1>
          </div>
      </div>
    
    <div class="mb-3">
      <router-link 
        v-if="bakBtn"
        class="el-button" 
        active-class="el-button--primary" 
        to="/bak">Bakalavriat</router-link>
      <router-link 
        v-if="false"
        class="el-button" 
        active-class="el-button--primary" 
        to="/mag">Magistratura</router-link>
    </div>
    <router-view/>
    <!-- <UserDashboard v-if="role=='admin'"/> -->
  </div>
</template>

<script>
import { checkAccess } from '@/services/helpers'
// import UserDashboard from '@/components/admin/AdminDashboard.vue';





export default {
  name: 'HomeView',
  data: () => ({
    magBtn:true,
    bakBtn:true
  }),  
  mounted(){
    this.$store.dispatch('statistic')
    this.$store.dispatch('gender')
    // this.$store.dispatch('chartResult')
    this.magBtn = checkAccess('bak','mag',0)
    this.bakBtn = checkAccess('bak','bak',0)
    if (!this.bakBtn && this.magBtn){
      this.$router.push('/mag')
    }
    if (!this.bakBtn && !this.magBtn){
      this.$router.push('/')
    }
    
  },
  
}
</script>
<style>
  .row{
    display: flex;
    align-items: center;
    margin-left: -15px;
    margin-right: -1px;
    flex-wrap: wrap;
  }

  .col-lg-4{
    width: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
    padding: 15px;
  }
  
  .col-lg-12{
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding: 15px;
  }
h3{
  text-align: center;
}
.box-card{
  width: 100%;
}
</style>
