import axios from "axios"
import router from "@/router"
import places from '@/dates/places'
export const students = {
  state: () => ({
    students: [],
    masters: [],
    oneStudent: {
      studyId: '',
    },
    study: [
      'Umumiy o`rta maktab',
      'Kasb-hunar kolleji',
      'Akademik litsey',
      'Universitet',
    ],
    studyName: [
      'Umumiy o`rta maktab',
      'Kasb hunar kolleji',
      'Akademik litsey',
      'Oliy ta`lim muassasi',
    ],
    types: [
      {title: 'Kunduzgi', value: 0},
      { title: 'Sirtqi', value: 1 },
      { title: 'Kechki', value: 2 },
    ],
    foreign: ['Ingliz tili', 'Nemis tili', 'Fransuz tili'],
    country: [
      { name: "O'zbekiston Respublikasi", code: 'uz' },
      { name: "Qozog'iston Respublikasi", code: 'kz' },
      { name: 'Tojikiston Respublikasi', code: 'tj' },
      { name: 'Turkmaniston Respublikasi', code: 'tk' },
      { name: "Qirg'iziston Respublikasi", code: 'kg' },
      { name: 'Boshqa', code: 'ot' },
    ],
    countstudent: 0,
    countMaster: 0,
    chartResult: [[], []],
    magchartResult: [[], []],

    findresult: [],
    provinceResult: [
      {
        counts: [
          { counts: [0, 0] },
          { counts: [0, 0] },
          { counts: [0, 0] },
          { counts: [0, 0] },
          { counts: [0, 0] },
        ],
      },
    ],
    studyPart:['Bakalavr','Magistr'],
    languages:[
        {title: "O'zbekcha", value: 0},
        {title: "Ruscha", value: 1},
    ],
    statusList: [
        {
            title: 'Qabul qilindi',
            type: 'success'
        },
        {
            title: 'Tekshiruvda',
            type: 'warning'
        },
        {
            title: 'Bekor qilindi',
            type: 'danger'
        },
        {
            title: 'Arxivda',
            type: 'warning'
        },
        {
            title: 'Talaba',
            type: 'success'
        },
    ]
  }),

  getters: {
    provinceResult(state) {
      return state.provinceResult;
    },
    findresult(state) {
      return state.findresult;
    },
    masters(state) {
      return state.masters;
    },

    countstudent(state) {
      return state.countstudent;
    },
    countMaster(state) {
      return state.countMaster;
    },
    chartResult({chartResult}) {
      return chartResult;
    },
    magchartResult(state) {
      return state.magchartResult;
    },
    studyName(state) {
      return state.studyName;
    },
    country(state) {
      return state.country;
    },
    foreign(state) {
      return state.foreign;
    },

    types(state) {
      return state.types;
    },
    study(state) {
      return state.study;
    },
    getStudents(state) {
      return state.students;
    },
    oneStudent(state) {
      return state.oneStudent;
    },
  },
  mutations: {
    findresult(state, payload) {
      state.findresult = payload;
    },
    chartResult(state, payload) {
      state.chartResult = payload;
    },
    magchartResult(state, payload) {
      state.magchartResult = payload;
    },
    addStudent(state, payload) {
      state.students.unshift(payload);
    },

    oneStudent(state, payload) {
      state.oneStudent = payload;
    },

    students(state, payload) {
      state.students = payload;
    },
    deleteStudent(state, payload) {
      let index = state.students.findIndex((f) => f._id == payload);
      if (index !== -1) {
        state.students.splice(index, 1);
      }
    },
    upStudent(state, payload) {
      let index = state.students.findIndex((f) => f._id == payload._id);
      if (index !== -1) {
        state.students[index] = payload;
      }
    },

    checkStatus(state, payload) {
      state.oneStudent.status = payload.status;
    },
    countStudent(state, payload) {
      state.countstudent = payload;
    },
    countMaster(state, payload) {
      state.countMaster = payload;
    },
    allreport(state, payload) {
      state.allreport = payload;
    },
    masters(state, payload) {
      state.masters = payload;
    },
    provinceResult(state, payload) {
      state.provinceResult = payload      
    },
  },

  actions: {
    getChartResult(context,payload){
      axios.get(`${context.getters.url}/statistic/student`,{
          headers: context.getters.header,
          params: payload
      })
      .then(res=> {
          if (res.status==200){
              context.commit('chartResult',res.data.result)
          }
      })
  },
    async provinceResult({ getters, commit }) {
      let res = await axios.get(`${getters.url}/statistic/provincestatistic`, {
        headers: getters.header,
      });
      if (res.status == 200) {
        
        let regions = res.data.regions.map((item) => {
            item.districts = places.districts.filter(district => district.region_id == item.id)
            item.districts = item.districts.map(district => {
                district.students = item.students.filter(student => student.district == district.id)
                

            return district
          });
          return item;
        });
        commit('provinceResult', regions);
      }
    },
    findresult(context, payload) {
      axios
        .get(`${context.getters.url}/student/search/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('findresult', res.data);
          }
        });
    },

    norm(context) {
      axios
        .get(`${context.getters.url}/student/norm`, {
          headers: context.getters.header,
        })
    },
    removeFile(context, payload) {
      return axios.post(`${context.getters.url}/student/files/delete`, {
        resFile: payload,
      });
    },
    sendSms(context, payload) {
      return axios.post(`${context.getters.url}/student/sendsms`, payload, {
        headers: context.getters.header,
      });
    },
    addStudent(context, payload) {
      return axios.post(
        `${context.getters.url}/student/registration`,
        payload,
        {
          headers: context.getters.header,
        }
      );
    },
    getCountStudents(context, payload) {
      axios
        .get(`${context.getters.url}/student/count`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('countStudent', res.data.studentAllCount);
          }
        });
    },
    getCountMasters(context, payload) {
      axios
        .get(`${context.getters.url}/student/count`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('countMaster', res.data.studentAllCount);
          }
        });
    },

    getStudents(context, payload) {
      // console.log(payload)
      return axios
        .get(`${context.getters.url}/student`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('students', res.data);
          }
        });
    },
    getMasters(context, payload) {
      return axios
        .get(`${context.getters.url}/student`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('masters', res.data);
          }
        });
    },

    oneStudent(context, payload) {
      return axios
        .get(`${context.getters.url}/student/getone/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('oneStudent', res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    activate(context, payload) {
      axios
        .get(`${context.getters.url}/student/activate/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('checkStatus', res.data);
          }
          router.push(res.data.studyPart == 0 ? '/students' : '/masters');
        })
        .catch((e) => {
          console.log(e);
        });
    },

    cancelUser(context, payload) {
      axios
        .get(`${context.getters.url}/student/notaccepted/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('checkStatus', res.data);
          }
          router.push(res.data.studyPart == 0 ? '/students' : '/masters');
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getStudent(context, payload) {
      return axios.get(`${context.getters.url}/student/getone/${payload}`, {
        headers: context.getters.header,
      });
    },
    editStudent(context, payload) {
      return axios.get(`${context.getters.url}/student/edit/${payload}`, {
        headers: context.getters.header,
      });
    },
    deleteStudent(context, payload) {
      axios
        .delete(`${context.getters.url}/student/archive/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('deleteStudent', payload);
          }
        });
    },
    notExam(context, payload) {
      axios
        .get(`${context.getters.url}/student/examnot/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('deleteStudent', payload);
          }
        });
    },
    allStudent(context) {
      axios
        .get(`${context.getters.url}/student`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('students', res.data);
          }
        });
    },
    excelStudents(context, payload) {
      return axios.get(`${context.getters.url}/student/excel`, {
        params: { ...payload },
        headers: context.getters.header,
      });
    },

    saveStudent(context, payload) {
      return axios.post(
        `${context.getters.url}/student/save/${payload._id}`,
        payload,
        {
          headers: context.getters.header,
        }
      );
    },

    chartResult(context) {
      axios
        .get(`${context.getters.url}/statistic/daystatistic`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('chartResult', res.data);
            context.commit('magchartResult', res.data.mag);
          }
        });
    },
  },
};