import { createStore } from 'vuex'

import { layoutApp } from './modules/layout'
import { auth } from './modules/auth'
import { notification } from './modules/notif'
import { modal } from './modules/modal'

import { direction } from './data/direction'
import { students } from './data/student'
import { report } from './data/report'
import { helpers } from './helpers'
import { exam } from './data/exam'
// import { crud } from './data/crud'
import { template } from './data/template'
import { page } from './data/page'
import { role } from './data/role'

import { subject } from './data/subject'
import { test } from './data/test'
import { testpack } from './data/testpack'
import { testproccess } from './data/testproccess'
import { msg } from './data/msg'
import { theme } from './data/theme'
import { worker } from './data/worker'

export default createStore({
  state: {
    url: 'https://qabulapi.namxu.uz',
    // url: 'http://localhost:3006',
    month:['Yanvar','Fevral','Mart','Aprel','May','Iyun','Iyul','Avgust','Sentabr','Oktabr','Noyabr','Dekabr'],
    loading:false,
  },
  getters: {
    month(state){
      return state.month
    },
    url(state){
      return state.url
    },
    loading({loading}){
      return loading
    }
  },
  mutations: {
    setLoading(state,payload){
      state.loading = payload
    }
  },
  actions: {
    loading({commit},payload){
      commit('setLoading',payload)
    }
  },
  modules: {
    layoutApp, 
    auth, 
    helpers,
    notification, 
    modal,  
    direction, 
    students, 
    report,
    template,
    worker,
    exam, page, role, subject, test, testpack, testproccess, msg, theme
  }
})
