<template>
  <div class="main">
    <header-item/>
    <div class="main__row">

      <div class="wrapper">
        <wrapper-header/>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import WrapperHeader from '@/components/WrapperHeader.vue';
import HeaderItem from '../components/HeaderItem.vue'
export default {
    components: { HeaderItem, WrapperHeader },
    created(){
      this.$store.dispatch('allDirection')
      // this.$store.dispatch('getStudents')
    }
}
</script>

<style>

</style>