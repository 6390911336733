import axios from "axios"


export const report = {
  state: () => ({
    reports: [],
    report: {},
    reportCount: 0,
    reportexcel: [],
    summa: 0,
    payments: 0,
    paymentsToday: 0,
    reportstat: [],
    reportstoday: 0,
    paymentsReports: 0,
    reportHome: [],
    reportHomeTitles: [],
    magreportHome: [],
    magreportHomeTitles: [],
    maxQuantity:0
  }),
  getters: {
    maxQuantity({maxQuantity}){
        return maxQuantity
    },
    reportHome(state) {
      return state.reportHome;
    },
    reportHomeTitles(state) {
      return state.reportHomeTitles;
    },
    magreportHome(state) {
      return state.magreportHome;
    },
    magreportHomeTitles(state) {
      return state.magreportHomeTitles;
    },
    paymentsReports(state) {
      return state.paymentsReports;
    },
    reportsToday(state) {
      return state.reportsToday;
    },
    reportstat(state) {
      return state.reportstat;
    },
    summa(state) {
      return state.summa;
    },
    payments(state) {
      return state.payments;
    },
    paymentsToday(state) {
      return state.paymentsToday;
    },
    reportexcel(state) {
      return state.reportexcel;
    },
    reportCount(state) {
      return state.reportCount;
    },
    reports(state) {
      return state.reports;
    },
    report(state) {
      return state.report;
    },
  },
  mutations: {
    reportsToday(state, payload) {
      state.reportsToday = payload;
    },
    maxQuantity(state,payload){
        state.maxQuantity = payload
    },
    reportHome(state,payload){
      state.reportHome = payload
    },
    paymentsReports(state, payload) {
      state.paymentsReports = payload;
    },
    reportstat(state, payload) {
      state.reportstat = payload;
    },
    reportCount(state, payload) {
      state.reportCount = payload;
    },
    reports(state, payload) {
      state.reports = payload;
    },
    report(state, payload) {
      state.report = payload;
    },
    delReport(state, payload) {
      let index = state.reports.findIndex((f) => f._id == payload);
      if (index !== -1) {
        state.reports.splice(index, 1);
      }
    },
    saveReport(state, payload) {
      state.reports = state.reports.map((report) => {
        if (report._id === payload._id) return payload;
        return report;
      });
    },
    delReportSum(state, payload) {
      state.reports = state.reports.map((report) => {
        if (report._id === payload._id) {
          report.payments.splice(payload.index, 1);
          return report;
        }
        return report;
      });
    },
    changeStatus(state, payload) {
      state.reports = state.reports.map((report) => {
        if (report._id === payload._id) report.status = payload.status;
        return report;
      });
    },
    reportexcel(state, payload) {
      state.reportexcel = payload;
    },
    allSummaReport(state, payload) {
      state.summa = payload;
    },
    allPayReport(state, payload) {
      state.payments = payload;
    },
    paymentsToday(state, payload) {
      state.paymentsToday = payload;
    },
  },
  actions: {
    reportsHome(context,payload){
      axios.get(`${context.getters.url}/statistic/reporthome`,{
          headers: context.getters.header,
          params: payload
      }).then(res => {
          if (res.status == 200){
              context.commit('reportHome',res.data.result)
          }
      })
  },
    reportstat(context) {
      axios
        .get(`${context.getters.url}/statistic/dayreport`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('reportstat', res.data.result);
          }
        });
    },
    allSummaReport(context) {
      axios
        .get(`${context.getters.url}/report/reportceo`, {
          params: { done: 0 },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            let all = 0;
            let reportsToday = 0;
            let payments = 0;
            let today = 0;
            let paymentsReports = 0;
            let todayDate = new Date();
            res.data.forEach((item) => {
              all += item.price;
              let reportDay = new Date(item.createdAt);
              if (
                todayDate.getDate() == reportDay.getDate() &&
                reportDay.getMonth() == todayDate.getMonth()
              ) {
                reportDay++;
              }
              if (item.payments) {
                if (item.payments.length > 0) paymentsReports++;

                item.payments.forEach((p) => {
                  let d = new Date(p.date);
                  if (
                    d.getDate() == todayDate.getDate() &&
                    d.getMonth() == todayDate.getMonth()
                  ) {
                    today += p.sum;
                  }
                  payments += p.sum;
                });
              }
            });
            context.commit('reportexcel', res.data);
            context.commit('allSummaReport', all * 0.7);
            context.commit('paymentsToday', today);
            context.commit('paymentsReports', paymentsReports);
            context.commit('reportsToday', reportsToday);
            context.commit('allPayReport', payments);
          }
        });
    },
    delReportSum(context, payload) {
      axios
        .delete(
          `${context.getters.url}/report/delreportsum/${payload._id}/${payload.index}`,
          { headers: context.getters.header }
        )
        .then((res) => {
          if (res.status == 200) {
            context.commit('delReportSum', payload);
          }
        });
    },
    addreportsum(context, payload) {
      axios
        .post(`${context.getters.url}/report/addreportsum/`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('saveReport', res.data);
          }
        });
    },
    savereportsum(context, payload) {
      axios
        .post(`${context.getters.url}/report/savereportsum`, payload, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('saveReport', res.data);
          }
        });
    },

    saveReport(context, payload) {
      axios
        .post(
          `${context.getters.url}/report/savereport/${payload._id}`,
          payload,
          {
            headers: context.getters.header,
          }
        )
        .then((res) => {
          if (res.status == 201) {
            context.commit('saveReport', res.data);
          }
        });
    },
    delReport(context, payload) {
      axios
        .delete(`${context.getters.url}/report/delreport/${payload}`, {
          headers: context.getters.header,
        })
        .then(() => {
          context.commit('delReport', payload);
        });
    },
    getReport(context, payload) {
      return axios.get(`${context.getters.url}/report/getreport/${payload}`, {
        headers: context.getters.header,
      });
    },

    allReport(context, payload) {
      axios
        .get(`${context.getters.url}/report/allreport`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('reports', res.data);
          }
        });
    },
    async lastReport(context, payload) {
      let res = await axios.get(`${context.getters.url}/report/lastreport`, {
        params: { ...payload },
        headers: context.getters.header,
      });
      if (res.status == 200) {
        context.commit('maxQuantity', res.data[0].maxQty);
      }
    },
    getReportExcel(context, payload) {
      return axios.get(`${context.getters.url}/report/reportexcel`, {
        params: { ...payload },
        headers: context.getters.header,
      });
    },
    reportCount(context, payload) {
      axios
        .get(`${context.getters.url}/report/allreportcount`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('reportCount', res.data.reports);
          }
        });
    },
    addReport(context, payload) {
      return axios.post(`${context.getters.url}/report/addreport`, payload, {
        headers: context.getters.header,
      });
    },
    changeStatus(context, payload) {
      axios
        .get(
          `${context.getters.url}/report/changestatus/${payload._id}/${payload.status}`,
          {
            headers: context.getters.header,
          }
        )
        .then((res) => {
          if (res.status == 200) {
            context.commit('changeStatus', payload);
          }
        });
    },
  },
};
