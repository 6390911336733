<template>
  <component :is="layout"/>
</template>
<script>
import AdminLayout from '@/layout/adminLayout.vue';
import UserLayout from '@/layout/userLayout.vue';
import AuthLayout from '@/layout/authLayout.vue';
export default {
  components: {
    'admin-layout': AdminLayout,
    'user-layout': UserLayout,
    'auth-layout': AuthLayout,
  },
  computed:{
    layout(){
      return this.$store.getters.layout
    }
  },
  methods:{
    checkUser(){
      if (this.$cookies.isKey('user')){
        this.$store.dispatch('checkUser',this.$cookies.get('user'))
      } else {
        this.$router.push('/')
        this.$store.dispatch('setLayout','auth-layout')
      }
      
    }
  },
  watch:{
    // $route(){
    //   this.checkUser()
    // }
  },
  mounted(){
    this.checkUser()

  }
}
</script>

<style lang="scss">
@import './styles/reset.scss';
@import './styles/grid.scss';
@import './styles/main.scss';
@import './styles/lib/class.scss';
</style>
