<template>
  <header class="header">
    <router-link to="/" class="header__logo">
        <img src="@/assets/logo.png" alt="">
    </router-link>
    <div class="header__box">
        <ul class="header__links">
            <li>
                <router-link to='/bak'>
                <el-icon><DataAnalysis /></el-icon>
                <div class="title">Statistika</div>
                </router-link>
            </li>
            <li>
                <router-link to='/report'>
                <el-icon><DataLine /></el-icon>
                <div class="title">Hisobot</div>
                </router-link>
            </li>
            <li>
                <router-link to='/students'>
                <el-icon><User /></el-icon>
                <div class="title">Bakalavriat</div>
                </router-link>
            </li>
            <li>
                <router-link to='/masters'>
                <el-icon><Avatar /></el-icon>
                <div class="title">Magistratura</div>
                </router-link>
            </li>
            
            <li>
                <router-link to='/reports'>
                <el-icon><Files /></el-icon>
                <div class="title">Shartnomalar</div>
                </router-link>
            </li>
            <!-- <li>
                <router-link to='/worker'>
                <el-icon><User /></el-icon>
                <div class="title">Рабочие</div>
                </router-link>
            </li>
            <li>
                <router-link to='/salary'>
                <el-icon><Money /></el-icon>
                <div class="title">Зарплата</div>
                </router-link>
            </li> -->
            
            </ul>

    </div>
  </header>
</template>

<script>
import { DataAnalysis } from '@element-plus/icons-vue'

export default {
    data() {
        return {
            look: ""
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        findresult() {
            return this.$store.getters.findresult;
        }
    },
    methods: {
        toUrl(url) {
            this.$router.push(url);
            this.look = "";
            this.$store.commit("findresult", []);
        },
        findMe() {
            if (this.look) {
                this.$store.dispatch("findresult", this.look);
            }
            else {
                this.$store.commit("findresult", []);
            }
        },
        exit() {
            if (confirm("Вы хотите выйти?")) {
                this.$store.dispatch("exit");
                this.$router.push("/");
            }
        }
    },
    components: { DataAnalysis }
}
</script>

<style lang="scss">
@import '../styles/part/header.scss';
</style>